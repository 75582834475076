import { makeObservable, observable } from "mobx";
import {api, API_BASE} from "../service/api.service";

class RentContractDocumentStore {
  loading = false;
  documents = [];
  documentsCount = 0;
  document = undefined;
  preview = false;

  constructor() {
    makeObservable(this, {
      documents: observable,
      loading: observable,
      documentsCount: observable,
      preview: observable
    });
  }

  async getDocuments(contractNumber) {
    this.loading = true;
    const res = await api.get(`${API_BASE}/api/contract/${contractNumber}/documents/ZRENTAL_DOCS`);
    this.documents = res?.data || [];
    this.loading = false;
  }

  async getDocument(contractNumber, id) {
    this.loading = true;
    const res = await api.get(`${API_BASE}/api/contract/${contractNumber}/documents/ZRENTAL_DOCS/${id}`, {
      responseType: "blob"
    });
    this.document = res.data;
    this.loading = false;
    return res.data
  }

}

const rentContractDocumentStore = new RentContractDocumentStore();

export default rentContractDocumentStore;