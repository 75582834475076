import t from './translate.service'

export function getAlertProperties (alert) {
  let statusClass = ''
  let statusName = t.SCHEDULED_CONSULTATION
  if (alert.type === 'contract') {
    statusName = t.CONTRACT
    if (alert.status === 'VERIFIED') {
      statusClass = 'CONTRACT_VERIFIED'
    } else if (alert.status === 'IN_PROGRESS') {
      statusClass = 'PROFILE_CONTRACTS_STATUS_PENDING_SHORT'
    } else if (alert.status === 'RENT_OVERDUE') {
      statusClass = 'RENT_OVERDUE'
    } else if(alert.status === 'UNVERIFIED') {
      statusClass = 'CONTRACT_VERIFICATION_BTN'
    } else {
      statusClass = 'PROFILE_CONTRACTS_STATUS_INACTIVE'
    }
  } else if (alert.type === 'damage_report') {
    statusName = t.INQUIRY
    if (alert.status === 'RESOLVED') {
      statusClass = 'INQUIRY_RESOLVED'
    } else if (alert.status === 'IN_PROGRESS') {
      statusClass = 'INQUIRY_IN_PROGRESS'
    } else {
      statusClass = 'INQUIRY_SUBMITTED'
    }

  } else if (alert.type === 'general') {
    statusName = 'general'
    statusClass = 'general'
  }
  else if (alert.type === 'letter') {
    statusName = 'letter'
    statusClass = 'general'
  }
  else if (alert.type === 'letter') {
    statusName = 'letter'
    statusClass = 'general'
  }
  else if (alert.type === 'oc_inspection') {
    statusName = 'oc_inspection'
    statusClass = 'general'
  }
  else if (alert.type === 'RC' || (alert.type === 'certificate' && alert.status === 'RC')) {
    statusName = t.CERTIFICATE_RENT_RC_SUCCESS
    statusClass = ''
  }
  else if (alert.type === 'RPC' || (alert.type === 'certificate' && alert.status === 'RPC')) {
    statusName = t.CERTIFICATE_RENT_RPC_SUCCESS
    statusClass = ''
  }
  else if (alert.type === 'other') {
    statusName = 'other'
    statusClass = 'general'
  }


  return { statusName, statusClass }
}
