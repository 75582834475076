/**
 * Will contain data about the logged in user.
 */
import { extendObservable, action } from 'mobx'
import { extractResponse, extractError } from '../service/response.service'
import { api, API_BASE } from '../service/api.service'
import tokenService from '../service/token.service'
import contractService from '../service/contract.service'
import get from 'lodash/get'

const LOGIN_ENDPOINT = `${API_BASE}/api/auth/login`
const PROFILE_ENDPOINT = `${API_BASE}/api/user/profile`
const FORGOT_PASSWORD_ENDPOINT = `${API_BASE}/api/user/forgot-password`
const RESET_PASSWORD_ENDPOINT = `${API_BASE}/api/auth/reset-password`
const LOGOUT_ENDPOINT = `${API_BASE}/api/auth/logout`
const FIRST_SCHEDULE_ENDPOINT = `${API_BASE}/api/user/first-schedule`
const SET_EMAIL_ENDPOINT = `${API_BASE}/change-email`
const REVOKE_CHANGE_EMAIL_ENDPOINT = `${API_BASE}/revoke-change-email`

class User {
  constructor() {
    extendObservable(this, {
      token: undefined,
      user: null,
      notification: null,
      contracts: [],
      activeContract: null,
      get contractAddress() {
        if (!this.activeContract) {
          return null
        }
        return this.activeContract.address
      },
      userType: undefined,
      loginError: undefined,
      firstTimeLoading: false,
      userEmail: null,
      mailError: null
    })

    this.callbacks = []

    const token = tokenService.get()
    if (!token) return

    this.token = token
    this.firstTimeLoading = true

    this.loadUser(token)
  }

  initContractStore(store) {
    this.contractStore = store
  }

  submitNotification(accepted) {
    if (!accepted && this.notification.type === 'blocking') {
      this.notification = null
      this.logout()
      return location.reload() // eslint-disable-line no-restricted-globals
    } else {
      return api
        .post('/api/user/notification/' + this.notification.id, {
          accepted
        })
        .then(extractResponse)
        .then(
          action(notification => {
            if (notification) {
              this.notification.content = notification.content
              this.notification.type = notification.type
              this.notification.id = notification.id
            } else {
              this.notification = null
            }
          })
        )
    }
  }

  loadUser(token) {
    this.getProfile()
      .then(user => {
        return this.setupUser(user, token)
      })
      .then(() => {
        this.firstTimeLoading = false
      })
      .catch(err => {
        if (err.message === 'Unauthorized' || err.message === 'Invalid token') {
          this.logout()
          location.reload() // eslint-disable-line no-restricted-globals
        }
      })
  }

  setupUser(user, token) {
    this.user = user
    this.notification = user.notification
    this.contracts = user.contracts || []
    this.onUserChange()
  }

  /** 
   * Method logs in the user and returns the user data
   * or throws an error with the message
   * of an error that occured while logging in.
   *
   * @param username
   * @param password
   *
   * @param  {String} username    User's username or email for login
   * @param  {String} password    User's password for login
   * @return {Promise}            Resolves to object containing user data and token
   */

  getFirstSchedule() {
    return api
      .get(FIRST_SCHEDULE_ENDPOINT)
      .then(res => extractResponse(res))
  }

  login = action((ailem, darswops) => {
    this.loginError = null
    this.userEmail = ailem
    return api
      .post(LOGIN_ENDPOINT, { ailem, darswops })
      .then(response => {
        this.loginError = null

        const responseData = extractResponse(response)
        const { token, refreshToken } = responseData
        this.persistData({ token: token, refreshToken: refreshToken })
        this.token = token

        return this.getProfile()
          .then(user => {
            return this.setupUser(user, token)
          })
          .then(() => {
            this.firstTimeLoading = false
            this.contractStore.initContracts()
          })
      })
      .catch(err => {
        const msg = extractError(err, 'Error occured when trying to sign in.')
        const statusCode = get(err, 'response.data.statusCode') || get(err, 'response.body.statusCode')

        this.loginError = msg

        throw msg
      })
  })
  resendVerificationEmail = (fromRegistrationThankYou) => {
    this.loginError = null
    this.mailError = null

    return api
      .post('/api/user/resend-verification-email', { email: this.userEmail })
      .then(response => {
        const responseData = extractResponse(response)
        this.loginError = fromRegistrationThankYou ? null : 'RESEND_VERIFICATION_EMAIL_SUCCESS'
        this.mailError = fromRegistrationThankYou ? 'RESEND_VERIFICATION_EMAIL_SUCCESS' : null
        return responseData
      })
      .catch(err => {
        this.loginError = fromRegistrationThankYou ? null : 'RESEND_VERIFICATION_EMAIL_ERROR'
        this.mailError = fromRegistrationThankYou ? 'RESEND_VERIFICATION_EMAIL_ERROR' : null

        throw err
      })
  }
  onUserChange = () => {
    this.callbacks.forEach(fn => fn(this.user))
  }

  addOnUserChangeHandler = callbackHandler => {
    this.callbacks.push(callbackHandler)
  }

  /**
   * Will remove user data from store, purge the current user data from memory.
   */
  logout = action(() => {
    api
      .post(LOGOUT_ENDPOINT)
      .then(response => {
        tokenService.remove()
        contractService.removeActiveContract()
      })
    this.token = undefined
    this.loginError = undefined
    this.user = null
    this.contracts.clear()
    this.activeContract = null
    this.onUserChange()
    if (window.fcWidget) window.fcWidget.destroy()
  })

  getProfile() {
    return api.get(PROFILE_ENDPOINT).then(response => {
      this.loginError = null
      const userData = response.data
      return userData
    })
  }

  /**
   * Method first cleans the data and then persists it into localStorage.
   *
   * @param userData
   */
  persistData(data) {
    tokenService.create(data)
  }

  confirmNewsletterSubscription(id) {
    return api
      .post('/api/user/confirm-newsletter-subscription', { id })
      .then(response => {
        return extractResponse(response)
      })
      .catch(err => {
        throw new Error(extractError(err))
      })
  }

  recoverPasswordRequest({ email }) {
    return api
      .post(FORGOT_PASSWORD_ENDPOINT, { email: email })
      .then(response => {
        const responseData = extractResponse(response)
        return responseData
      })
      .catch(err => {
        const msg = extractError(
          err,
          'Error occured when trying to recover password.'
        )
        this.loginError = msg

        throw msg
      })
  }

  resetPassword({ password, confirmPassword, hash }) {
    return api
      .post(RESET_PASSWORD_ENDPOINT, { password, confirmPassword, hash })
      .then(res => {
        return { success: true }
      })
      .catch(err => {
        throw new Error(extractError(err))
      })
  }

  checkResetLink(hash) {
    return api.get(RESET_PASSWORD_ENDPOINT + '/' + hash)
  }

  changeEmail(hash) {
    return api
      .post(SET_EMAIL_ENDPOINT, { hash })
  }

  getUser() {
    return this.user
  }

  revokeChangeEmail(hash) {
    return api
      .post(REVOKE_CHANGE_EMAIL_ENDPOINT, { hash })
  }
}

export default new User()
