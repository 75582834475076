import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { extendObservable } from 'mobx'
import { Form, Button } from 'react-bootstrap'
import t from '../../service/translate.service'
import { Input } from '../../components/formComponents'
import { Link } from 'react-router-dom'
import ChangePasswordStore from '../../store/ChangePassword.store'
import ThankYouOverlay from '../../components/ThankYouOverlay'
import { extractError } from '../../service/response.service'
import { checkPasswordResponse } from '../../service/validate'
import openedEye from '../../assets/eye-regular.png'
import closedEye from '../../assets/eye-slash-solid.png'
import historyService from '../../service/history.service'
import { Alert } from 'antd'

class PasswordForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      oldPassword: false,
      newPassword: false,
      confirmPassword: false
    }
    extendObservable(this, {
      sending: false,
      changePasswordStore: new ChangePasswordStore(),
      successSubmit: false,
      error: false,
      errorMessage: ''
    })
  }

  onSubmit = e => {
    e.preventDefault()
    this.sending = false
    const submit = this.changePasswordStore.submit()
    if (submit) {
      submit.then(() => {
        this.sending = false
        this.successSubmit = true
      }).catch((err) => {
        const errMsg = extractError(err, 'Error occurred while submitting')
        this.errorMessage = checkPasswordResponse(errMsg)
        this.error = true
      })
    }
    this.sending = false
  }

  getAttrs = field => {
    const { model, setVal, validators } = this.changePasswordStore
    return {
      value: model[field],
      onChange: e => setVal(field, e.target.value),
      validate: validators[field]
    }
  }

  render() {
    if (this.successSubmit) {
      return (
        <ThankYouOverlay
          title={t.THANK_YOU}
          description={t.SAVE_PASSWORD}
          buttonText={t.BACK_TO_PROFILE}
          buttonLink='/profile'
        />
      )
    }
    return (
      <div>
        {this.error && (
          <Alert
            message={this.errorMessage}
            type="error"
            style={{ marginTop: '12px', marginBottom: '12px' }}
          />
        )}
        <Form onSubmit={this.onSubmit}>
          <div className="login-password-group form-group">
            <Input
              name="oldPassword"
              {...this.getAttrs('password')}
              type={this.state.oldPassword ? 'text' : 'password'}
              label={t['USER_OLD_PASSWORD'] + '*'}
              placeholder={t.FORM_PLACEHOLDER(t['USER_OLD_PASSWORD'])}
              styles={{ marginBottom: '23px' }}
            />
            <img
              className="resetPasswordOld"
              src={this.state.oldPassword ? openedEye : closedEye}
              onClick={() =>
                this.setState({
                  oldPassword: !this.state.oldPassword,
                })
              }
              alt=""
            />
          </div>
          <div className="login-password-group form-group">
            <Input
              name="password"
              {...this.getAttrs('newPassword')}
              type={this.state.newPassword ? 'text' : 'password'}
              label={t['USER_PASSWORD_NEW'] + '*'}
              placeholder={t.FORM_PLACEHOLDER(t['USER_PASSWORD_NEW'])}
              styles={{ marginBottom: '23px' }}
            />
            <img
              className="resetPasswordNew"
              src={this.state.newPassword ? openedEye : closedEye}
              onClick={() =>
                this.setState({
                  newPassword: !this.state.newPassword,
                })
              }
              alt=""
            />
          </div>
          <div className="login-password-group form-group">
            <Input
              name="passwordConfirm"
              {...this.getAttrs('passwordConfirm')}
              type={this.state.confirmPassword ? 'text' : 'password'}
              label={t['USER_PASSWORD_CONFIRM'] + '*'}
              placeholder={t.FORM_PLACEHOLDER(
                t['USER_PASSWORD_CONFIRM']
              )}
            />
            <img
              className="resetPasswordConfirm"
              src={this.state.confirmPassword ? openedEye : closedEye}
              onClick={() =>
                this.setState({
                  confirmPassword: !this.state.confirmPassword,
                })
              }
              alt=""
            />
          </div>
        </Form>
        <Form.Group
          style={{ margin: 30 }}
          className="actions text-center"
        >
          <div className="group">
            <Button
              style={{ marginRight: 20 }}
              type="submit"
              className="button primary"
              onClick={this.onSubmit}
              disabled={this.sending}
            >
              {t['USER_PASSWORD_SAVE']}
            </Button>
            <Link
              to="/profile"
              className="button secondary"
              onClick={() => historyService.push('/profile')}
            >
              {t['FORM_CANCEL']}
            </Link>
          </div>
        </Form.Group>
      </div>
    );
  }
}

export default observer(PasswordForm)
