import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { extendObservable } from 'mobx'
import { Link } from 'react-router-dom'
import { Input, Checkbox } from '../components/formComponents'
import t from '../service/translate.service'
import RegistrationStore from '../store/registrationStore'
import { Form, Button } from 'react-bootstrap'
import PrimaryTitle from '../components/PrimaryTitle'
import LoginRegisterSwitcher from '../components/LoginRegisterSwitcher'
import openedEye from '../assets/eye-regular.png'
import closedEye from '../assets/eye-slash-solid.png'
import history from '../service/history.service'
import withRouter from '../components/withRouter'
import { Alert } from 'antd'

class RegistrationPage extends Component {
  constructor(props) {
    super(props)
    extendObservable(this, {
      registrationStore: new RegistrationStore()
    })
    this.state = {
      showPassword: false,
      showConfirmPassword: false
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()
    await this.registrationStore.submit()
    .then(() => this.props.navigate('/register-success'))
  }

  getAttrs = field => {
    const { model, setVal, validators } = this.registrationStore
    return {
      value: model[field],
      onChange: e => setVal(field, e.target.value),
      validate: validators[field]
    }
  }

  render() {
    const { model, setVal, validators } = this.registrationStore

    return (
      <div>
        <PrimaryTitle title={t.WELCOME_TITLE} />

        <LoginRegisterSwitcher
          currentPage={history.location.pathname}
        />
        {this.registrationStore.submitErr && <Alert message={this.registrationStore.passwordErr ? this.registrationStore.passwordErr : t[this.registrationStore.submitErr]} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />}
        <Form
          onSubmit={this.onSubmit} className='registration'
        >
          <Input name='firstName' {...this.getAttrs('firstName')} label={t['USER_FIRST_NAME'] + '*'} />
          <Input name='lastName' {...this.getAttrs('lastName')} label={t['USER_LAST_NAME'] + '*'} />
          <Input name='email' {...this.getAttrs('email')} label={t['USER_EMAIL'] + '*'} />
          <Input name='phone' {...this.getAttrs('phone')} label={t['USER_PHONE'] + '*'} />

          <div className='login-password-group form-group'>
            <Input
              name='password'
              {...this.getAttrs('password')}
              label={t['USER_PASSWORD'] + '*'}
              type={this.state.showPassword ? 'text' : 'password'}
              styles={{ marginBottom: '23px' }}
            />
            <img className='showPassword' src={this.state.showPassword ? openedEye : closedEye} onClick={() => this.setState({ showPassword: !this.state.showPassword })} alt='' />
          </div>
          <div className='login-password-group form-group'>
            <Input
              name='confirmPassword'
              {...this.getAttrs('confirmPassword')}
              label={t['USER_PASSWORD_CONFIRM'] + '*'}
              type={this.state.showConfirmPassword ? 'text' : 'password'}
              styles={{ marginBottom: '23px' }}
            />
            <img className='showConfirmPassword' src={this.state.showConfirmPassword ? openedEye : closedEye} onClick={() => this.setState({ showConfirmPassword: !this.state.showConfirmPassword })} alt='' />
          </div>
          <Checkbox
            label={t['USER_WANT_NEWSLETTER']}
            value={model.subscribe}
            onChange={e => setVal('subscribe', !model.subscribe)}
            validate={validators['subscribe']}
          />
          <Checkbox
            labelBeforeText={t.TERMS_AND_CONDITIONS_1}
            labelLinkText={t.TERMS_AND_CONDITIONS_2}
            labelLink='https://www.grandcityproperty.de/datenschutz'
            labelAfterText={t.TERMS_AND_CONDITIONS_3}
            value={model.terms}
            onChange={e => setVal('terms', !model.terms)}
            error={t.PRIVACY_POLICY_REQUIRED}
            validate={validators['terms']}
          />
          <Form.Group className='actions text-center'>
            <Link to='/login'>{t.ALREADY_HAVE_ACCOUNT}</Link>
            <div className='text-center'>
              <Button
                type='submit'
                className='button primary'
              >
                {t.LOGIN_PAGE_REGISTER}
              </Button>
            </div>
          </Form.Group>
        </Form>
      </div>
    )
  }
}

export default withRouter(observer(RegistrationPage))
